import React from 'react'
import axios from '../services/axios';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import ScreenWrapper from '../components/ScreenWrapper';
import { baseApiUrl } from '../constants/actions.constants';
import InputField from '../components/InputField';
import GradientWrapper from '../components/GradientWrapper';
import Button from '../components/Button';
import { ReactComponent as Check } from '../assets/icons/check.svg';
import { useStore } from '../store/store';
import { useHistory } from 'react-router-dom';

export default function AdminPage() {
    const [state] = useStore();
    const router = useHistory();

    const [email, setEmail] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [programs, setPrograms] = React.useState([]);
    const [connectedPrograms, connectPrograms] = React.useState([]);
    const [buttonText,  setButtonText] = React.useState('Skapa') 
    const {auth: {user: {role}}} = state;

    React.useEffect(() => {
        if (role !== 'admin'){
            router.push('/')
        }
        axios({
            method: 'GET',
            url: baseApiUrl +`/products`,
        }).then(res => {
            setPrograms(res.data)
            return res.data;
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const flashCreated = async () => {
        setButtonText('Skapad')
        setTimeout(()=>{
            setButtonText('Skapa')
        }, 1000);
    }

    const onSubmit = async () => {
        const data =  {
            user: {
                first_name: firstName,
                last_name: lastName,
                email
            },
            programs: connectedPrograms
        };

        axios({
            method: 'POST',
            url: baseApiUrl +`/admin-user`,
            data
        }).then(res => {
            if (res.status === 200){
                setFirstName('');
                setLastName('');
                setEmail('');
                connectPrograms([]);
                flashCreated();
            }
        })
    }

    const addToConnect = program => {
        if (connectedPrograms.includes(program.id)){
            connectPrograms(connectedPrograms.filter(p=>p !== program.id))
        } else {
            connectPrograms([...connectedPrograms, program.id])
        }
    }

    return (
        <GradientWrapper withMenu>
            <ScreenWrapper title="Admin">
            <h3>Skapa användare</h3>
            <InputField label="Förnamn" name="firstName" type="text" value={firstName} onChange={setFirstName} />
            <InputField label="Efternamn" name="lastName" type="text" value={lastName} onChange={setLastName} />
            <InputField label="E-post" name="email" type="email" value={email} onChange={setEmail} />
            <div css={programsWrapper}>
            {programs && programs.map(program => (
                <div  key={program._id} css={[programStyle, connectedPrograms.includes(program.id) && activeProgram]}
                onClick={()=>addToConnect(program)}>
                    <div>{program.name}</div>
                    {connectedPrograms.includes(program.id) && React.createElement(Check, {width: 18, height: 16, fill: 'white'})}

                </div>
            ))}
            </div>
            <Button align="center" onClick={onSubmit}>{buttonText}</Button>

            </ScreenWrapper>
        </GradientWrapper>
    )
}

const programStyle = css`
background-color: white;
border-radius: 30px;
padding: 6px 18px;
margin: 5px 0;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
`;

const activeProgram = css`
background-color: #c0ecb8;
`;

const programsWrapper = css`
margin-bottom: 18px
`;