import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useStore } from '../../store/store';

export default function ProgramListItem({ program }) {
    const [state, dispatch] = useStore();
    const { auth: { user: { programs } } } = state;
    const purchased = programs && programs.some(p => p._id === program._id);

    const handleProgramClick = () => {
        if (purchased){
            dispatch({
                type: 'open_modal',
                payload: {
                    component: 'program',
                    props: {
                        data: programs.find(p=>p._id === program._id)
                    }
                }
            })
        }
        if (!purchased){
            dispatch({
                type: 'open_modal',
                payload: {
                    component: 'product',
                    props: {
                        data: program
                    }
                }
            })
        }
    }

    return (
        <div css={[wrapper]}>
            {!purchased && (<div css={[price]}>{program.total_price}kr</div>)}

            <div css={[imageWrapper]}><img css={[imageStyle]} src={program.images[0]?.src} alt="" /></div>
            <div css={[title]} >{program.name}</div>
            <div css={[decription]} dangerouslySetInnerHTML={{ __html: program.short_description }}></div>
            <div css={[footer]}>
                <div css={[button]} onClick={handleProgramClick}>{purchased ? "Let's go" : "Köp"}</div>
            </div>
            {/* <div>{program.program_data}</div> */}
        </div>
    )
}

const wrapper = css`
margin: 10px 0 20px;
position: relative;
@media (min-width: 768px) {
        width: calc(50% - 40px);
        min-width: 320px;
        margin: 10px 20px;
        display:inline-block;
    }
    @media (min-width: 1134px) {
        width: calc(33.333333% - 40px);
        min-width: 320px;
        margin: 10px 20px;
        display:inline-block;
    }
`;

const imageWrapper = css`
position: relative;
padding-top: 62.5%;
width: 100%;
overflow: hidden;
`;

const imageStyle = css`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
width: 100%;
`;

const title = css`
margin: 8px 0 0;
font-weight: bold;
font-size: 18px;
`;

const decription = css`
p {
margin: 4px 0 0;
}
`;

const buttonStyle = css`
padding: 3px 20px;
background-color: #A691E3;
color: white;
border-radius: 16px;
box-shadow: 0 0 10px rgba(0,0,0,0.1);
&:active {
    background-color: #feddfe;
}
`;

const price = css`
position: absolute;
top: 10px;
right: 10px;
z-index: 1;
${buttonStyle}
`;

const footer = css`
display: flex;
justify-content: flex-end;
`;

const button = css`
${buttonStyle}
padding: 6px 20px;
display: inline-block;
`;