import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import ProgramListItem from './ProgramListItem'

const wrapper = css`
width: 100%;
height: 100%;
`;

export default function ProgramList({ programs, loading = false }) {
    return (
        <div css={[wrapper]}>
            {
            loading ? <div css={[css`height: 100%; display: flex; align-items: center; justify-content: center;`]}>
            Laddar...
        </div> : !programs.length ? <div css={[css`height: 100%; display: flex; align-items: center; justify-content: center;`]}>
            Här saknas det något :(
        </div> :
            programs.map(program => <ProgramListItem key={program._id} program={program}/>)
        }
        </div>
    )
}
