import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ReactComponent as PlayIcon } from '../assets/icons/play.svg';
import { ReactComponent as CheckIcon } from '../assets/icons/check.svg';
import { useStore } from '../store/store';

export default function ProgramPage({ data, closeModal }) {
    // eslint-disable-next-line no-unused-vars
    const [state, dispatch] = useStore();
    const {auth: {user}} = state;
    const workoutsForThisProgram = user.workoutsDone.filter(w=>w.program = data._id)

    const openWorkout = (workout) => {
        dispatch({
            type: 'open_modal',
            payload: {
                component: 'workout',
                props: {
                    data: {...workout, img: data.images[0].src, programId: data._id}
                }
            }
        })
    }

    return (
        <>
            <div css={[header]}>
                <div css={[closeButton]} onClick={() => closeModal('program')} />
                <div css={[css`background-image: url(${data.images[0].src});`, headerBackground]}></div>
            </div>
            <div css={[body]}>
                <h2>{data.name}</h2>
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
                <div>
                    {data.product_data.weeks.map((week, i) => (
                        <div key={`week-${i}`}>
                            <h3>{week.title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: week.week_description }} />
                            <div>
                                {week.days.map((day, i) => (
                                    <div css={[dayStyle, workoutsForThisProgram.some(w=>w.workout === day.title) && doneDay]} key={`day-${i}`} onClick={() => openWorkout(day)}>
                                        <div>
                                            {day.title}
                                        </div>
                                        {workoutsForThisProgram.some(w=>w.workout === day.title) ? <CheckIcon height="18" /> : <PlayIcon height="18" />}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

const header = css`
@supports (-webkit-touch-callout: none) {
    margin-top: calc(env(safe-area-inset-top) - 6px);
}
padding-top: calc(56.25% + 40px);
position: relative;
`;

const headerBackground = css`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-size: cover;
background-position: top center;
`;

const body = css`
padding: 0 20px calc(env(safe-area-inset-bottom) + 8px);
`;

const closeButton = css`
position: absolute;
top: 12px;
right: 12px;
z-index: 10;
background-color: white;
height: 38px;
width: 38px;
border-radius: 50%;
transform: rotateZ(45deg);
&::before{
    content: '';
    position: absolute;
    width: 22px;
    height: 3px;
    background-color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
}

&::after{
    content: '';
    position: absolute;
    width: 3px;
    height: 22px;
    background-color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
}
`;

const dayStyle = css`
display: flex;
justify-content:space-between;
align-items: center;
padding: 24px 24px 24px 24px;
margin: 8px 0;
box-shadow: 0 0 10px rgba(0,0,0,0.2);
border-radius: 40px;
font-weight: bold;
&:active{
    background-color: #feddfe;
    background-color: #feddfe;
}
`;
const doneDay = css`
    background-color: #c0ecb8;

`;