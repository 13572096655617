export const formatTime = (timer, format) => {
  const leadingZero = typeof (format.leadingZero) != 'undefined' ? format.leadingZero : true;
  const currentFormat = {
    hour: typeof format.hour !== 'undefined' ? format.hour : true,
    minute: typeof format.minute !== 'undefined' ? format.minute : true,
    second: typeof format.second !== 'undefined' ? format.second : true
  }
  const baseSeconds = `${leadingZero ? '0' : ''}${(timer % 60)}`
  const getSeconds = leadingZero ? baseSeconds.slice(-2) : baseSeconds;
  const minutes = `${Math.floor(timer / 60)}`
  const getMinutes = !currentFormat.minute ? '' : `0${minutes % 60}`.slice(-2) + ':'
  const getHours = !currentFormat.hour ? '' : `0${Math.floor(timer / 3600)}`.slice(-2)  + ':'

  return `${getHours}${getMinutes}${getSeconds}`
}
