import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Header from './Header';

const wrapper = css`
height: calc(100% - 90px);
overflow-y: scroll;
`;

const body = css`
padding: 0 20px;
height: calc(100% - 100px);
`;

const nopaddingStyle = css`
padding: 0;
height: 100%;

`;

export default function ScreenWrapper({ children, withHeader = true, title, nopadding, customHeader }) {
  return (
    <div css={[wrapper]}>
      {withHeader && !customHeader && (
        <Header title={title}/>
      )}
      {customHeader && customHeader}
      <div css={[body, nopadding && nopaddingStyle]}>
        {children}
      </div>
    </div>
  )
}
