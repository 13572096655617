import React from 'react';
import { useStore } from '../store/store';
/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { ReactComponent as CartIcon } from '../assets/icons/shopping-cart.svg';
import { dismissCartNoti } from '../actions/cart.actions';

export default function Cart() {
    const [state, dispatch] = useStore();
    const { cart, modals } = state;

    const openCart = (workout) => {
        dispatch({
            type: 'open_modal',
            payload: {
                component: 'cart',
            }
        })
    }
    React.useEffect(() => {
        setTimeout(() => {
            dismissCartNoti(dispatch);
        }, 5100)
    }, [cart.noti, dispatch])

    const handleDismissNoti = () => {
        dismissCartNoti(dispatch);
    }
    if ((!!cart.items.length && !modals.length) || !!cart.noti) {
        return (
            <div>
                <div css={[cartIcon, cart.noti && notiStyle]} onClick={cart.noti ? handleDismissNoti : openCart}>
                    <span css={[cartItemBadge, cart.noti && css`display:none;`]}>{cart.items.length}</span>
                    <CartIcon height="20" />
                    {cart.noti && (
                        <p>{cart.noti}</p>
                    )}
                </div>
            </div>
        )
    }
    return null;
}

const popIn = keyframes`
0%{transform: scale(0);}
80%{transform: scale(1.1);}
100%{transform: scale(1);}
`;

const popInNOut = keyframes`
0%{transform: scale(0);}
8%{transform: scale(1.1);}
13%{transform: scale(1);}
87%{transform: scale(1);}
92%{transform: scale(1.1);}
100%{transform: scale(0);}
`;

const cartIcon = css`
position: fixed;
bottom: 60px;
right: 20px;
z-index:999;
background-color: #c0ecb8;
width: 38px;
height: 38px;
display: flex;
justify-content:center;
align-items: center;
border-radius: 50%;
animation:${popIn} .25s forwards;
svg { 
    margin-left: -2px;
}
&:active {
    background-color: #feddfe;
}
`;

const cartItemBadge = css`
position: absolute;
top: -6px;
right: -6px;
width: 20px;
height: 20px;
border-radius: 50%;
display: flex;
justify-content:center;
align-items: center;
background-color: #FEDDFE;
font-size: 14px;
`;

const notiStyle = css`
bottom: 0;
right: 0;
width: 100%;
height: 60px;
border-radius: unset;
border-top-left-radius: 20px;
border-top-right-radius: 20px;
box-sizing: border-box;
transform-origin:  center bottom;
animation:${popInNOut} 3s forwards;

svg {
    margin-right: 6px;
}
`;