import {
    FETCH_USER_PROGRAMS_INIT,
    FETCH_USER_PROGRAMS_SUCCESS,
    FETCH_USER_PROGRAMS_ERROR,
    USER_FETCH_INIT,
    USER_FETCH_SUCCESS,
    USER_FETCH_ERROR,
    USER_RESET_PASSWORD_INIT,
    USER_RESET_PASSWORD_SUCCESS,
    USER_PASSWORD_RESET_ERROR,
} from '../constants/actions.constants';
import axios from '../services/axios';
import { baseApiUrl } from '../constants/actions.constants';

export const getUserPrograms = (dispatch) => {
    dispatch({
        type: FETCH_USER_PROGRAMS_INIT,
    })

    axios({
        method: 'GET',
        url: baseApiUrl + '/users/me',
    }).then(({ data }) => {
        dispatch({
            type: FETCH_USER_PROGRAMS_SUCCESS,
            payload: data.programs
        })
    }).catch(err => {
        console.log(err);
        dispatch({
            type: FETCH_USER_PROGRAMS_ERROR,
            payload: err.response
        })
    });
}

export const getMe = (dispatch) => {
    dispatch({
        type: USER_FETCH_INIT,
    })

    return axios({
        method: 'GET',
        url: baseApiUrl + '/users/me',
    }).then((res) => {
        dispatch({
            type: USER_FETCH_SUCCESS,
            payload: res.data
        })
        return res;
    }).catch(err => {
        console.log(err);
        dispatch({
            type: USER_FETCH_ERROR,
            payload: err.response
        })
    });
}

export const newPassword = (data, dispatch) => {
    dispatch({
        type: USER_RESET_PASSWORD_INIT
    })

    return axios({
        method: 'PUT',
        url: baseApiUrl + '/password',
        data
    }).then((res) => {
        dispatch({
            type: USER_RESET_PASSWORD_SUCCESS,
            payload: res.data
        })
        return res;
    }).catch(err => {
        dispatch({
            type: USER_PASSWORD_RESET_ERROR,
            payload: err.response
        })
        return err.response;
    });
}