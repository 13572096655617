import React from 'react'
/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'

export default function Contextmenu({ open, items, handleItemClick, toggle }) {
    return (
        <div>
            {open && (
                <div>
                    <div css={[overlay]} onClick={toggle}></div>
                    <div css={[contextMenuStyle]}>
                        {items.map(item => <ContextmenuItem key={item.name} {...item} toggleContextMenu={toggle} handleClick={handleItemClick} />)}
                    </div>
                </div>

            )}
        </div>
    )
}

function ContextmenuItem({ label, name, handleClick, toggleContextMenu }) {
    return (
        <div key={name}>
            <div css={[contextMenuItemStyle]} onClick={() => {
                toggleContextMenu();
                handleClick(name);
            }}>{label}</div>

        </div>
    )
}
const slideDown = keyframes`
0%{transform: translateY(85%); opacity: 0;}
100%{transform: translateY(100%); opacity: 1;}
`;
const contextMenuStyle = css`
right: 0;
bottom: 0;
position:absolute;
z-index: 9999;
width: 180px;
border-radius: 20px;
border-top-right-radius: 0;
background-color: #2b2b2b;
animation: ${slideDown} 0.3s forwards;
transform: translate(-12px, 100%);
`;

const contextMenuItemStyle = css`
padding: 12px 20px;
color: white;
text-align: right;
`;

const overlay = css`
position: fixed;
top: 0;right: 0;
bottom: 0;left: 0;
z-index:1;
`;