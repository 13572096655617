export function setCookie(name, value, days) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    const secure = window.location.protocol === 'https:' ? 'Secure;' : null;
    document.cookie = name + '=' + (encodeURIComponent(value) || '') + expires + '; path=/; SameSite=Lax;' + secure;
  }
  
  export function getCookie(name) {
      var nameEQ = name + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
      }
    return null;
  }
  
  export function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
  }
  
  
  export function setStorage(key, value, days) {
    const now = new Date();
  
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + (days * 24 * 60 * 60 * 1000)
    };
    localStorage.setItem(key, JSON.stringify(item))
  }
  
  export function getStorage(key) {
    const itemStr = localStorage.getItem(key)
  
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
  
    const item = JSON.parse(itemStr)
    const now = new Date()
  
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }
  
  export function deleteStorage(key) {
    localStorage.removeItem(key);
  }
  
  export function clearStorage(){
    localStorage.clear();
    eraseCookie('auth');
    eraseCookie('sek-webshop-cart');
    eraseCookie('auth_token');
  }
  