import React from 'react'
import GradientWrapper from '../components/GradientWrapper'
import Header from '../components/Header'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import InputField from '../components/InputField'
import Button from '../components/Button'
import { signupUser } from '../actions/auth.actions'
import { useStore } from '../store/store'
import { useHistory, Redirect } from 'react-router-dom'


export default function SignupPage() {
    const router = useHistory();
    // eslint-disable-next-line no-unused-vars
    const [state, dispatch] = useStore();
    const [error, setError] = React.useState([]);
    const [sent, setSent] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordVerify, setPasswordVerify] = React.useState('');

    const verifyPassword = () => {
        if (password !== passwordVerify) {
            setError([{ field: 'password-verify', message: 'Lösenorden stämmer inte överrens' }])
            return false;
        }
        const passwordRegex = new RegExp(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#$^+=!?*()@%&.,_-]).{8,24}$'
        );
        if (!passwordRegex.test(password)) {
            setError([{ field: 'password-verify', message: 'Ditt lösenord är inte säkert, läs texten ovan' }])
            return false;
        }
        return true;
    }

    const onSubmit = () => {
        setError([]);
        if (!verifyPassword) return;
        signupUser({ email, password }, dispatch).then(res => {
            if (res.status === 400) {
                setError([{ field: 'email', message: 'Det finns redan ett konto med denna epostadress' }])
                return false;
            }
            if (res.status === 200) {
                setSent(true)
            }
        });
    }

    if (state.auth.isAuthenticated) {
        return (
            <Redirect to="/" />
        )
    }


    return (
        <GradientWrapper>
            <div css={[css`display:flex; flex-direction: column; justify-content:space-between;height: 100%;`]}>
                {!sent ? (<>
                    <Header title="Varmt välkommen!" />
                    <div css={[body]}>
                        <h3>Detta går snabbt!</h3>
                        <p>Snart kan du ta del av saftiga träningsprogram! <br />Fyll bara i uppgifterna nedan för att skapa ditt konto</p>
                        <InputField name="email" onChange={setEmail} label="E-postadress" error={error} />
                        <InputField type="password" name="password" onChange={setPassword} label="Lösenord" error={error} />
                        <InputField type="password" name="password-verify" onChange={setPasswordVerify} label="Lösenord igen" error={error} />
                        <Button align="center" onClick={onSubmit}>Färdig!</Button>
                    </div>
                </>
                ) : (
                    <>
                        <Header title="Woop woop!" />
                        <div css={[body]}>
                            <h3>Kolla inboxen :)</h3>
                            <p>För att logga in så måste du först verifiera ditt konto. <br />Vi har skickat ett mail, allt du vbehöver göra är att klicka på knappen, sen är det bara att köra!</p>
                            <Button align="center" onClick={() => router.push('/login')}>Logga in!</Button>
                        </div>
                    </>
                )}
            </div>
        </GradientWrapper>
    )
}
const body = css`
box-sizing: border-box;
display:flex;
flex-direction:column;
justify-content:flex-end;
padding: 0 20px calc(env(safe-area-inset-bottom) + 12px);

h2{
    margin-bottom: 0;
}
p {
    margin-bottom: 24px;
}

@media (min-width: 768px) {
        max-width: 400px;
        margin: 0 auto;
    }
`;