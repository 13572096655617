import React from 'react'
/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
export default function GradientWrapper({children,withMenu}) {
    return (
        <div css={[gradientWrapper, withMenu && menuStyle]}>
            {children}
        </div>
    )
}
const gradientAnimation = keyframes`
    0% {background-position: 20% 0%}
    50% {background-position: 81% 100%}
    100% {background-position: 20% 0%}
`;

const gradientWrapper = css`
    height: 100%;
    background: linear-gradient(0deg, #c0ecb8, #feddfe, #A691E3);
    background-size: 400% 400%;
    animation: ${gradientAnimation} 10s ease infinite;
`;

const menuStyle = css`
height: calc(100%  - 90px);
`;