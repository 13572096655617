import axios from '../services/axios';
import { baseApiUrl } from '../constants/actions.constants';

import {
    ACTIVITY_NEW_INIT,
    ACTIVITY_NEW_SUCCESS,
    ACTIVITY_NEW_ERROR,
    ACTIVITY_UPDATE_INIT,
    ACTIVITY_UPDATE_SUCCESS,
    ACTIVITY_UPDATE_ERROR
} from '../constants/actions.constants'
export const newActivity = async (data, dispatch) => {
    dispatch({
        type: ACTIVITY_NEW_INIT,
    });

    return axios({
        method: 'POST',
        url: baseApiUrl +`/activity`,
        data,
    }).then(res => {
        dispatch({
            type: ACTIVITY_NEW_SUCCESS,
            payload: res.data,
        });
        return res.data;
    }).catch(err => {
        dispatch({
            type: ACTIVITY_NEW_ERROR,
            payload: err,
        });
    });
};


export const updateActivity = async (data, dispatch) => {
    dispatch({
        type: ACTIVITY_UPDATE_INIT,
    });

    return axios({
        method: 'PUT',
        url: baseApiUrl +`/activity`,
        data,
    }).then(res => {
        dispatch({
            type: ACTIVITY_UPDATE_SUCCESS,
            payload: res.data,
        });
        return res.data;
    }).catch(err => {
        dispatch({
            type: ACTIVITY_UPDATE_ERROR,
            payload: err,
        });
    });
};