import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const splitTitle = title => {
    if (!title) {
      return '';
    }
    return title.split('|').map((row, index, split) => (
      <span key={`${encodeURIComponent(row)}`}>
        {row}
        {split.length > 1 && index !== split.length - 1 && <br />}
      </span>
    ));
  };
  
export default function Header({title, children, mini}) {
    return (
        <div css={[header]}>
          {title && (
            <div css={headerText}>
              {splitTitle(title)}
            </div>
          )}
          {children}
        </div>
    )
}

const header = css`
display: flex;
justify-content: space-between;
align-items: center;
height: 100px;
padding-top: 20px;
padding-bottom: env(safe-area-inset-bottom);
padding-left: 20px;
padding-right: 20px;
box-sizing: border-box;
@supports (-webkit-touch-callout: none) {
  padding-top: calc(env(safe-area-inset-top) + 40px);
}
`;

const headerText = css`

font-size: 24px;
font-weight: bold;
`;