import React from 'react'
;import {
    Route,
    Redirect
  } from "react-router-dom";
import { useStore } from '../store/store';

function PrivateRoute ({ children, component, ...rest }) {
  const [state] = useStore();
  const {auth} = state;
  if (rest.redirect){
    return <Redirect to={rest.redirect} />
  }
    return (
      <Route {...rest} render={() => {
        return auth.isAuthenticated
          ? React.createElement(component)
          : <Redirect to='/login' />
      }} />
    )
  }

  export default PrivateRoute;