import React from 'react'
import ReactDOM from 'react-dom';

/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'
import { useStore } from '../store/store';
import ProgramPage from '../pages/program.page';
import WorkoutPage from '../pages/workout.page';
import ProductPage from '../pages/product.page';
import CartPage from '../pages/cart.page';

export default function Modal({ children, fullscreen = false, onClose }) {
    const [state, dispatch] = useStore();
    const [closeModal, setCloseModal] = React.useState([]);
    const { modals } = state;

    const handleClose = (component) => {
        setCloseModal(component);
        setTimeout(() => {
            dispatch({
                type: 'close_modal',
                payload: component
            });
            setCloseModal([closeModal.filter(modal => modal.component !== component)]);

        }, 500)
    }

    const getModalContent = (modal) => {
        if (modal.component === 'program') {
            return <ProgramPage closeModal={handleClose} {...modal.props} />
        }
        if (modal.component === 'workout') {
            return <WorkoutPage closeModal={handleClose} {...modal.props} />
        }
        if (modal.component === 'product') {
            return <ProductPage closeModal={handleClose} {...modal.props} />
        }
        if (modal.component === 'cart') {
            return <CartPage closeModal={handleClose} {...modal.props} />
        }
    }

    if (modals.length) {
        return modals.map(modal => (
            ReactDOM.createPortal((
                <div css={[modalStyle, !closeModal.includes(modal.component) ? toOpen : toClose]}>
                    {getModalContent(modal) || children}
                </div>
            ), document.body)
        ));
    }

    return <div />
}

const animateIn = keyframes`
0% {
    top: 20%;
    opacity: 0;
}
100% {
    top: 0;
    opacity: 1;
}
`;
const animateOut = keyframes`
0% {
    top: 0%;
    opacity: 1;
}
100% {
    top: 20;
    opacity: 0;
}
`;

const modalStyle = css`
position: fixed;
top: 20%;
right: 0;
bottom: 0;
left: 0;
background-color: white;
overflow-y: scroll;
z-index:1;
`;

const toOpen = css`
animation: ${animateIn} .5s forwards;
`;

const toClose = css`
animation: ${animateOut} .5s forwards;
`;