import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useStore } from '../store/store';
import Button from '../components/Button';
// import { addToCart } from '../actions/cart.actions';

export default function ProductPage({ data, closeModal }) {
    // eslint-disable-next-line no-unused-vars
    const [state, dispatch] = useStore();
    // const handleAddToCart = () => {
    //     addToCart(data._id, dispatch)
    // }

    return (
        <div>
            <div css={[header]}>
                <div css={[closeButton]} onClick={() => closeModal('product')} />
                <div css={[css`background-image: url(${data.images[0].src});`, headerBackground]}></div>
            </div>
            <div css={[body]}>
                <h2>{data.name}</h2>
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
                <div>
                    {data.product_data && data.product_data.weeks.map((week, i) => (
                        <div key={`week-${i}`}>
                            <h3>{week.title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: week.week_description }} />
                            <div>
                                {week.days.map((day, i) => (
                                    <div css={[dayStyle]} key={`day-${i}`}>
                                        <div>
                                            {day.title}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div css={[css`text-align: center;`]}>
                    <Button href={data.permalink} target="_blank">Köp via hemsidan</Button>
                    {/* <Button onClick={handleAddToCart}>Lägg i kundvagnen</Button> */}
                </div>
            </div>

        </div>
    )
}

const header = css`
@supports (-webkit-touch-callout: none) {
    margin-top: calc(env(safe-area-inset-top) - 6px);
}
padding-top: calc(56.25% + 40px);
position: relative;
`;

const headerBackground = css`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-size: cover;
background-position: top center;
`;

const body = css`
padding: 0 20px calc(env(safe-area-inset-bottom) + 10px);
`;

const closeButton = css`
position: absolute;
top: 12px;
right: 12px;
z-index: 10;
background-color: white;
height: 38px;
width: 38px;
border-radius: 50%;
transform: rotateZ(45deg);
&:active {
    transform: rotateZ(-45deg);
    background-color: black;
    &:before, &:after {
        background-color: white;
    }
}
&::before{
    content: '';
    position: absolute;
    width: 22px;
    height: 3px;
    background-color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
}

&::after{
    content: '';
    position: absolute;
    width: 3px;
    height: 22px;
    background-color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
}
`;

const dayStyle = css`
display: flex;
justify-content:space-between;
padding: 8px 0;
`;