import React from 'react'
import GradientWrapper from '../components/GradientWrapper'
import Header from '../components/Header'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import InputField from '../components/InputField'
import Button from '../components/Button'
import { requestNewPassword } from '../actions/auth.actions'
import { useStore } from '../store/store'
import { useHistory, Redirect } from 'react-router-dom'

export default function ResetPassRequest() {
    const router = useHistory();
    // eslint-disable-next-line no-unused-vars
    const [state, dispatch] = useStore();
    const [sent, setSent] = React.useState(false);
    const [email, setEmail] = React.useState('');

    const onSubmit = () => {
        requestNewPassword(email, dispatch);
        setSent(true);
    }

    if (state.auth.isAuthenticated) {
        return (
            <Redirect to="/" />
        )
    }
    
    return (
        <GradientWrapper>
            <div css={[css`display:flex; flex-direction: column; justify-content:space-between;height: 100%;`]}>
            <Header title="Glömt lösenordet" />
            {!sent ? (
                <div css={[body]}>
                    <h2>Ingen fara :)</h2>
                    <p>Fyll i din e-post nedan så hjälper vi dig att skapa ett nytt</p>
                    <InputField name="email" onChange={setEmail} label="E-postadress" errors={[]} />
                    <Button align="center" onClick={onSubmit}>Hjälp mig</Button>
                </div>
            ) : (
                <div css={[body]}>
                    <h2>Kolla inkorgen</h2>
                    <p>Om du har ett konto hos oss så har vi skickat ett mail till dig med instruktioner hur du skapar ett nytt lösenord.</p>
                    <Button align="center" onClick={() => router.push('/login')}>Gå tillbaka</Button>
                </div>
            )}
            </div>
        </GradientWrapper>
    )
}
const body = css`
box-sizing: border-box;
display:flex;
flex-direction:column;
justify-content:center;
padding: 0 20px calc(env(safe-area-inset-bottom) + 12px);

h2{
    margin-bottom: 0;
}
p {
    margin-bottom: 24px;
}

    @media (min-width: 768px) {
        max-width: 400px;
        margin: 0 auto;
    }
`;