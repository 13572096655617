export const baseApiUrl = process.env.NODE_ENV === 'production' ? 'https://shapeu.se/api' : 'http://192.168.86.28:1338/api';


export const AUTH_USER_INIT = 'AUTH_USER_INIT';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';

export const USER_FETCH_INIT = 'USER_FETCH_INIT';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_ERROR = 'USER_FETCH_ERROR';

export const USER_RESET_PASSWORD_INIT = 'USER_RESET_PASSWORD_INIT';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';

export const USER_SIGNUP_INIT = 'USER_SIGNUP_INIT';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_ERROR = 'USER_SIGNUP_ERROR';

export const USER_VERIFY_INIT = 'USER_VERIFY_INIT';
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS';
export const USER_VERIFY_ERROR = 'USER_VERIFY_ERROR';

export const USER_PASSWORD_REQUEST_INIT = 'USER_PASSWORD_REQUEST_INIT';
export const USER_PASSWORD_REQUEST_SUCCESS = 'USER_PASSWORD_REQUEST_SUCCESS';
export const USER_PASSWORD_REQUEST_ERROR = 'USER_PASSWORD_REQUEST_ERROR';


export const USER_PASSWORD_RESET_INIT = 'USER_PASSWORD_RESET_INIT';
export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS';
export const USER_PASSWORD_RESET_ERROR = 'USER_PASSWORD_RESET_ERROR';


export const FETCH_USER_PROGRAMS_INIT = 'FETCH_USER_PROGRAMS_INIT';
export const FETCH_USER_PROGRAMS_SUCCESS = 'FETCH_USER_PROGRAMS_SUCCESS';
export const FETCH_USER_PROGRAMS_ERROR = 'FETCH_USER_PROGRAMS_ERROR';


export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_NOTI_DISMISS = 'CART_NOTI_DISMISS';

export const ACTIVITY_NEW_INIT = 'ACTIVITY_NEW_INIT';
export const ACTIVITY_NEW_SUCCESS = 'ACTIVITY_NEW_INIT';
export const ACTIVITY_NEW_ERROR = 'ACTIVITY_NEW_INIT';

export const ACTIVITY_UPDATE_INIT = 'ACTIVITY_NEW_INIT';
export const ACTIVITY_UPDATE_SUCCESS = 'ACTIVITY_NEW_INIT';
export const ACTIVITY_UPDATE_ERROR = 'ACTIVITY_NEW_INIT';