import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import InputField from '../components/InputField';
import Button from '../components/Button';
import Header from '../components/Header';
import logo from '../assets/img/SUUP.png';
import { loginUser } from '../actions/auth.actions';
import { useStore } from '../store/store';
import { useHistory, Redirect } from 'react-router-dom';
import GradientWrapper from '../components/GradientWrapper';

export default function LoginPage() {
    const [state, dispatch] = useStore();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState([]);
    const router = useHistory();

    const onSubmit = async () => {
        const submitErrors = [];
        const res = await loginUser({ email, password }, dispatch);
        if (res.status === 200) {
            router.push('/');
        }
        if (res.status === 404) {
            submitErrors.push({ field: 'email', message: 'Användare saknas' });
        }
        if (res.status === 403) {
            submitErrors.push({ field: 'email', message: 'Användare ej verifierad' });
        }
        if (res.status === 422) {
            submitErrors.push({ field: 'password', message: 'Felaktigt lösenord' });
        }
        setError(submitErrors);

    }

    if (state.auth.isAuthenticated) {
        return (
            <Redirect to="/" />
        )
    }

    return (
        <GradientWrapper>
            <Header title="Logga in">
            </Header>
            <div css={[body]}>
                <img css={[css`transform: scale(1); height:406px; width: 330px; margin: 0 auto;`]} src={logo} alt="" />
                <div css={[inputFieldWrapper]}>
                    <InputField label="E-post" name="email" type="email" onChange={setEmail} error={error} />
                    <InputField label="Lösenord" name="password" type="password" onChange={setPassword} error={error} />
                    <Button align="center" onClick={onSubmit}>Logga in</Button>
                    <div css={[css`margin-top: 12px;`]}>
                        <div css={[actionTextStyle]}>
                            <span onClick={()=> router.push('/forgot-password')}>Glömt lösenordet?</span>
                    </div>

                        <div css={[actionTextStyle]}>
                            Inte medlem? <span onClick={()=>router.push('/signup')}>Skapa konto nu!</span>
                    </div>
                    </div>

                </div>

            </div>
        </GradientWrapper>
    )
}

const body = css`
box-sizing: border-box;
height: calc(100% - 100px);
display:flex;
flex-direction:column;
justify-content:center;
padding: 0 20px 300px;
`;

const inputFieldWrapper = css`
position: absolute;
bottom: 0;
left: 0;
right: 0;
padding: 0 20px;
    button {
        margin-top: 6px;
    }
    @media (min-width: 768px) {
        max-width: 400px;
        margin: 0 auto;
    }
`;

const actionTextStyle = css`
text-align: center;
margin-bottom: 10px;
span {
    color:white;
    text-decoration: underline;
    font-weight: 700;
}
`;