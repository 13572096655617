import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export default function Button({ children, size = 'large', align, onClick, href, target }) {

    const getButtonSize = () => {
        switch (size) {
            case 'large':
                return large;
            case 'medium':
                return medium;
            case 'small':
            default:
                return small;
        }
    }

    const getButtonAlign = () => {
        switch (align) {
            case 'right':
                return right;
            case 'center':
                return center;
            case 'left':
            default:
                return left;
        }
    }

    return (
        <div css={[getButtonAlign()]}>
            {href ? (
                <a css={[button, getButtonSize()]} href={href} target={target}>{children}</a>
            ) : (
            <button css={[button, getButtonSize()]} onClick={onClick}>{children}</button>
            )}
        </div>
    )
}
const buttonStyle = css`
background-color: #A691E3;
color: white;
border-radius: 16px;
box-shadow: 0 0 10px rgba(0,0,0,0.1);
text-decoration: none;
font-weight: bold;
&:active {
    background-color: #feddfe;
}
`;

const left = css`

`;

const center = css`
text-align: center;
`;

const right = css`
text-align: right;
`;


const small = css`
padding: 3px 20px;
`;
const medium = css`
padding: 7px 28px;
`;

const large = css`
padding: 12px 54px;
border-radius: 30px;
font-size: 18px;
`;

const button = css`
${buttonStyle}
padding: 6px 20px;
display: inline-block;
border: 0;
margin: 0 auto;
`;