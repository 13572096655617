import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import ScreenWrapper from '../components/ScreenWrapper'
import InputField from '../components/InputField';
import { useStore } from '../store/store';
import GradientWrapper from '../components/GradientWrapper';
import Button from '../components/Button';
import { newPassword } from '../actions/user.actions';
import Header from '../components/Header';
import { useHistory } from 'react-router-dom';

export default function UserSettingsPage() {
    const [state, dispatch] = useStore();
    const router = useHistory();
    const { auth: { user } } = state;
    const [error, setError] = React.useState([]);
    const [sent, setSent] = React.useState(false);
    const [oldPassword, setOldPassword] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordVerify, setPasswordVerify] = React.useState('');

    const verifyPassword = () => {
        if (password !== passwordVerify) {
            setError([{ field: 'password-verify', message: 'Lösenorden stämmer inte överrens' }])
            return false;
        }
        const passwordRegex = new RegExp(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#$^+=!?*()@%&.,_-]).{8,24}$'
        );
        if (!passwordRegex.test(password)) {
            setError([{ field: 'password-verify', message: 'Ditt lösenord är inte säkert, läs texten ovan' }])
            return false;
        }
        return true;
    }

    const onSubmit = () => {
        setError([]);
        if (!verifyPassword()) return;
        newPassword({ oldPassword, password }, dispatch).then(res => {
            if (res.status === 406) {
                return setError([{ field: 'old-password', message: 'Felaktigt lösenord' }])
            }
            if (res.status === 200) {
                setSent(true);
                setTimeout(() => {
                    setSent(false);
                }, 2000)
            } else {
                setError([{ field: 'password-verify', message: 'Någonting gick fel. Försök igen senare' }])
            }
        });
    }

    return (
        <GradientWrapper>
            <ScreenWrapper customHeader={
                <Header title="Inställningar">
                    <Button size="medium" onClick={()=>{router.goBack();}}>Tillbaka</Button>
                </Header>
            }>
                <h3 css={[headerStyle]}>
                    {user.email}
                </h3>
                <div css={[body]}>
                    <h3>Byt lösenord</h3>
                    <p>Lösenordet måste innehålla minst 8 tecken, minst en STOR bokstav, minst en s1ffr4 och minst ett spec!altecken</p>

                    <InputField type="password" label="Gammalt lösenord" name="old-password" onChange={setOldPassword} error={error} />
                    <InputField type="password" label="Nytt lösenord" name="password" onChange={setPassword} error={error} />
                    <InputField type="password" label="Nytt lösenord igen" name="password-verify" onChange={setPasswordVerify} error={error} />
                    <div css={[css`text-align: center;`]}>
                        <Button onClick={onSubmit}>{sent ? 'Sparat' : 'Spara'}</Button>
                    </div>
                </div>
            </ScreenWrapper>
        </GradientWrapper>
    )
}

const headerStyle = css`
text-align: center; 
background-color: rgba(255,255,255,0.5);
padding: 12px;
border-radius:30px;
@media (min-width: 768px) {
        max-width: 400px;
        margin: 0 auto;
    }
`;

const body = css`
@media (min-width: 768px) {
        max-width: 400px;
        margin: 0 auto;
    }`;