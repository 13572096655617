const initialState =  {
    auth: {
        isAuthenticated: false,
        token: null,
        user: {
            programs: []
        },
    },
    products: [],
    modals: [],
    cart: {
        items: [],
        sum: 0,
        noti: ''
    },
    ui: {
        activeNavTab: null,
    },
    loading: false,
};

export default initialState;