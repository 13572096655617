import React from 'react'
/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react'

import { useHistory, useLocation } from 'react-router-dom';

export default function NavigationBar({ routes, routeNameVisible }) {
    const router = useHistory();
    const location = useLocation();
    const [isHidden, setIsHidden] = React.useState(true);
    const activeRef = React.useRef();
    const [activeItemPostition, setActiveItemPostition] = React.useState(0);
    
    React.useEffect(() => {
        if (!routes.some(route => route.path === location.pathname)) {
            setIsHidden(true);
        } else {
            setIsHidden(false);
        }
        const ref = activeRef.current;
        if (ref) {
            setActiveItemPostition(ref.offsetLeft)
        }
    }, [location.pathname, routes])

    const handleItemClick = route => {
        router.push(route.path)
    }

    return (
        <div css={[navBar, isHidden ? hideBar : showBar]}>
            <div css={[activeTabStyle, css`left: ${activeItemPostition}px;`]}>
                <div css={[activeNavBarItem]} />
            </div>
            {routes.map(route => (
                <div key={route.path} css={[navBarItem]}
                    onClick={() => handleItemClick(route)}
                    ref={location.pathname === route.path ? activeRef : null}
                >
                    {route.icon && (
                        <div css={[location.pathname === route.path && activeIcon]}>
                            {React.createElement(route.icon.component, route.icon.props)}
                        </div>
                    )}
                    {routeNameVisible && (
                        <div>
                            {route.name}
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}


const navBar = css`
position: fixed;
bottom: 0;
left: 0;
right: 0;
height:  80px;
background-color: #A691E3;
display: flex;
justify-content: space-around;
overflow: hidden;
padding: 0 40px;
@media (min-width: 768px) {
        max-width: 400px;
        margin: 0 auto;
        overflow: visible;
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 100vw;
            height: 100%;
            transform: translateX(-50%);
            background-color:#A691E3;
            z-index:-1;
        }
    }
`;

const activeTabStyle = css`
position: absolute;
left: 0;
transition: left 0.25s ease-in-out;
height: calc(100% - 20px);
width: 80px;
background-color: white;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
box-shadow:  0 15px 20px rgba(0,0,0,0.2);
`;
const navBarItem = css`
position: relative;
height: calc(100% - 20px);
width: 80px;
display: flex;
justify-content: center;
align-items: center;
&:active {
    svg{ 
        fill: white;
    }
}
`;

const activeIcon = css`
transform: translateY(-6px) scale(1.1);
transition: transform .25s ease-in-out;
transition-delay: .25s;
`;

const activeNavBarItem = css`
background-color: white;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;

&:before, &:after {
  position: absolute;
  top: -1px;
  width: 20px;
  height: 20px;
  content: " ";
  border: 1px solid white;
}

&:before {
  right: -20px;
  border-width: 1px 0 0 1px;
  border-top-left-radius: 20px;
  box-shadow: -8px -8px 0 white;
}
&:after {
  left: -20px;
  border-width: 1px 1px 0 0;
  border-top-right-radius: 20px;
  box-shadow: 8px -8px 0 white;
}
`;
const slideUp = keyframes`
from {
    bottom: -100%
}
to { bottom: 0;}
`;
const slideDown = keyframes`
from {
    bottom: 0
}
to { bottom: -100%;}
`;
const hideBar = css`
animation: ${slideDown} 1.5s forwards
`;
const showBar = css`
animation: ${slideUp} .75s forwards
`;