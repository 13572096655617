import { AUTH_USER_ERROR, AUTH_USER_INIT, AUTH_USER_SUCCESS, LOGOUT_USER, USER_PASSWORD_REQUEST_ERROR, USER_PASSWORD_REQUEST_INIT, USER_PASSWORD_REQUEST_SUCCESS, USER_PASSWORD_RESET_ERROR, USER_PASSWORD_RESET_INIT, USER_PASSWORD_RESET_SUCCESS, USER_SIGNUP_ERROR, USER_SIGNUP_INIT, USER_SIGNUP_SUCCESS, USER_VERIFY_ERROR, USER_VERIFY_INIT, USER_VERIFY_SUCCESS } from '../constants/actions.constants';
import axios from '../services/axios'
import { setStorage } from '../services/localStorage';
import { baseApiUrl } from '../constants/actions.constants';

export const checkAppVersion = (version) => {
  return axios({
    method: 'GET',
    url: baseApiUrl + '/version',
  }).then(({ data: { version } }) => version);
}

export const checkExistingUser = (email) => {

}

export const loginUser = (data, dispatch) => {
  dispatch({
    type: AUTH_USER_INIT,
  });

  return axios({
    url: baseApiUrl + '/login',
    method: 'POST',
    data
  }).then((res) => {
    dispatch({
      type: AUTH_USER_SUCCESS,
      payload: res.data.data
    })
    setStorage('token', res.data.data.token, 365);
    return res;
  }).catch(e => {
    console.log(e);
    dispatch({
      type: AUTH_USER_ERROR,
      payload: e.response
    })
    return e.response;
  })
};

export const logoutUser = (dispatch) => {
  dispatch({
    type: LOGOUT_USER,
  });
};


export const requestNewPassword = (email, dispatch) => {
  dispatch({
    type: USER_PASSWORD_REQUEST_INIT,
  });

  return axios({
    url: baseApiUrl + '/reset-password-request',
    method: 'POST',
    data: { email }
  }).then((res) => {
    dispatch({
      type: USER_PASSWORD_REQUEST_SUCCESS,
      payload: res.data
    })
    return res;
  }).catch(e => {
    console.log(e);
    dispatch({
      type: USER_PASSWORD_REQUEST_ERROR,
      payload: e.response
    })
    return e.response;
  })
};

export const resetPassword = (data, dispatch) => {
  dispatch({
    type: USER_PASSWORD_RESET_INIT,
  });

  return axios({
    url: baseApiUrl + '/reset-password',
    method: 'POST',
    data,
  }).then((res) => {
    dispatch({
      type: USER_PASSWORD_RESET_SUCCESS,
      payload: res.data
    })
    return res;
  }).catch(e => {
    console.log(e);
    dispatch({
      type: USER_PASSWORD_RESET_ERROR,
      payload: e.response
    })
    return e.response;
  })
};

export const signupUser = (data, dispatch) => {
  dispatch({
    type: USER_SIGNUP_INIT,
  });

  return axios({
    url: baseApiUrl + '/user',
    method: 'POST',
    data: { user: data },
  }).then((res) => {
    dispatch({
      type: USER_SIGNUP_SUCCESS,
      payload: res.data
    })
    return res;
  }).catch(e => {
    console.log(e);
    dispatch({
      type: USER_SIGNUP_ERROR,
      payload: e.response
    })
    return e.response;
  })
};


export const verifyUser = (data, dispatch) => {
  dispatch({
    type: USER_VERIFY_INIT,
  });

  return axios({
    url: baseApiUrl + '/verify',
    method: 'POST',
    data,
  }).then((res) => {
    dispatch({
      type: USER_VERIFY_SUCCESS,
      payload: res.data
    })
    return res;
  }).catch(e => {
    console.log(e);
    dispatch({
      type: USER_VERIFY_ERROR,
      payload: e.response
    })
    return e.response;
  })
};