import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useStore } from '../store/store';
import Button from '../components/Button';
import ScreenWrapper from '../components/ScreenWrapper';
import { createKlarnaOrder, removeFromCart } from '../actions/cart.actions';
import { ReactComponent as TrashIcon } from '../assets/icons/trash-alt.svg';
import Header from '../components/Header';

export default function CartPage({ data, closeModal }) {
    // eslint-disable-next-line no-unused-vars
    const [state, dispatch] = useStore();
    const { cart } = state;
    const [snippet, setSnippet] = React.useState('');

    const handleRemoveFromCart = (item) => {
        removeFromCart(item, dispatch);
    }

    const handleGoToCheckout = () => {

        createKlarnaOrder().then(res => {
            setSnippet(res.data.html_snippet)
        })

    }

    if (snippet) {
        return (
            <ScreenWrapper withHeader={false} customHeader={
            <Header title="Kassa">
            <div css={[closeButton]} onClick={() => closeModal('cart')} />
        </Header>
    }>
                

                <div dangerouslySetInnerHTML={{ __html: snippet }} />
            </ScreenWrapper>
        );
    }

    return (
        <ScreenWrapper withHeader={false} customHeader={
        <Header title="Kundvagnen">
        <div css={[closeButton]} onClick={() => closeModal('cart')} />
    </Header>
}>

            <div css={[body]}>
                {cart.items.map((item, i) => (
                    <div key={`${item.id}-${i}`} css={[cartItemRow]}>
                        <div css={[cartItemRowContent]}>
                            <div css={[cartItemImage]}>
                                <img src={item.images[0].src} alt="" />
                            </div>
                            <div>
                                <div css={[title]}>{item.name}</div>
                                <div css={[price]}>{item.total_price}kr</div>
                            </div>
                        </div>
                        <div onClick={() => handleRemoveFromCart(item)}><TrashIcon height="22" /></div>
                    </div>
                ))}
                <div css={[css`position:absolute; bottom: calc(env(safe-area-inset-bottom) + 10px); left: 0; text-align: center; width: 100%;`]}>
                    <Button onClick={handleGoToCheckout}>Gå till kassan</Button>
                </div>
            </div>
        </ScreenWrapper>
    )
}

const body = css`
`;

const cartItemRow = css`
display: flex;
justify-content: space-between;
align-items: center;
`;

const cartItemRowContent = css`
display: flex;
`;
const cartItemImage = css`
margin-right: 6px;
img {
height: 48px;
width: 48px;
object-fit: cover;
}
`;
const title = css`
font-weight: bold;
`;
const price = css``;

const closeButton = css`
position: relative;
margin-right: -8px;
height: 38px;
width: 38px;
transform: rotateZ(45deg);
&:active {
    transform: rotateZ(-45deg);
    background-color: red;
}
&::before{
    content: '';
    position: absolute;
    width: 22px;
    height: 3px;
    background-color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
}

&::after{
    content: '';
    position: absolute;
    width: 3px;
    height: 22px;
    background-color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
}
`;
