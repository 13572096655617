import React from 'react'
import GradientWrapper from '../components/GradientWrapper'
import Header from '../components/Header'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import InputField from '../components/InputField'
import Button from '../components/Button'
import { resetPassword } from '../actions/auth.actions'
import { useStore } from '../store/store'
import { useHistory, Redirect } from 'react-router-dom'
import qs from 'query-string';

export default function ResetPass() {
    const router = useHistory();
    // eslint-disable-next-line no-unused-vars
    const [state, dispatch] = useStore();
    const [error, setError] = React.useState([]);
    const [invalid, setInvalid] = React.useState(false);
    const [sent, setSent] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordVerify, setPasswordVerify] = React.useState('');
    const { id, token } = qs.parse(router.location.search);

    const verifyPassword = () => {
        if (password !== passwordVerify) {
            setError([{ field: 'password-verify', message: 'Lösenorden stämmer inte överrens' }])
            return false;
        }
        const passwordRegex = new RegExp(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#$^+=!?*()@%&.,_-]).{8,24}$'
        );
        if (!passwordRegex.test(password)) {
            setError([{ field: 'password-verify', message: 'Ditt lösenord är inte säkert, läs texten ovan' }])
            return false;
        }
        return true;
    }

    const onSubmit = () => {
        setError([]);
        if (!verifyPassword()) return;
        resetPassword({ userId: id, password, token }, dispatch).then(res => {
            if (res.status === 401) {
                setInvalid(true);
            }
            if (res.status === 200) {
                setSent(true);
            }
        });
    }

    if (state.auth.isAuthenticated) {
        return (
            <Redirect to="/" />
        )
    }

    return (
        <GradientWrapper>
            <div css={[css`display:flex; flex-direction: column; justify-content:space-between;height: 100%;`]}>
                <Header title="Nytt lösenord" />
                <div css={[body]}>
                    {!invalid && !sent ? (
                        <>
                            <h2>Snart är du igång!</h2>
                            <p>Fyll i ditt nya lösenord här nedan. <br />Lösenordet måste innehålla minst 8 tecken, minst en STOR bokstav, minst en s1ffr4 och minst ett spec!altecken</p>
                            <InputField type="password" name="passsword" onChange={setPassword} label="Nytt lösenord" error={error} />
                            <InputField type="password" name="password-verify" onChange={setPasswordVerify} label="Nytt lösenord igen" error={error} />
                            <Button align="center" onClick={onSubmit}>Klar</Button>
                        </>
                    ) : invalid && !sent ? (
                        <>
                            <h2>Too slow</h2>
                            <p>Antingen tog du lite för lång tid på dig, eller så har du redan skapat ett nytt lösnord. Gå till sidan "Glömt Lösenord" för att beställa en ny länk.</p>
                            <Button align="center" onClick={() => router.push('/forgot-password')}>Beställ ny länk</Button>
                        </>

                    ) : (
                        <>
                            <h2>Lösenord sparat!</h2>
                            <p>Nu kan du logga in med ditt nya lösenord :)</p>
                            <Button align="center" onClick={() => router.push('/login')}>Logga in</Button>
                        </>
                    )}
                </div>
            </div>
        </GradientWrapper>
    )
}
const body = css`
box-sizing: border-box;
display:flex;
flex-direction:column;
justify-content:center;
padding: 0 20px calc(env(safe-area-inset-bottom) + 12px);

h2{
    margin-bottom: 0;
}
p {
    margin-bottom: 24px;
}

@media (min-width: 768px) {
        max-width: 400px;
        margin: 0 auto;
    }
`;