/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import useTimer from '../hooks/useTimer';
import { formatTime } from '../utils/formatTime';
import Button from '../components/Button';
import { ReactComponent as PauseIcon } from '../assets/icons/pause.svg';
import { ReactComponent as PlayIcon } from '../assets/icons/play.svg';
import { ReactComponent as ForwardIcon } from '../assets/icons/fast-forward.svg';
import { ReactComponent as BackwardIcon } from '../assets/icons/fast-backward.svg';
import { ReactComponent as FullscreenIcon } from '../assets/icons/expand-wide.svg';
import { newActivity, updateActivity } from '../actions/program.actions';
import { useStore } from '../store/store';


export default function WorkoutPage({ data, closeModal }) {
    // eslint-disable-next-line no-unused-vars
    const [state, dispatch] = useStore();
    const videoRef = React.useRef();
    const [videoPlaying, setVideoPlaying] = React.useState(true);
    const [activeActivity, setActiveActivity] = React.useState(null);
    const [activeChapter, setActiveChapter] = React.useState({ title: '' });
    const time = useTimer(0, true, false);
    const [videoDimensions, setVideoDimensions] = React.useState({ height: 0, width: 0 })
    const headerRef = React.useRef(null);
    const isFirstRun = React.useRef(true);

    React.useEffect(() => {
        const ref = videoRef.current;
        if (ref) {
            setTimeout(() => {
                setVideoDimensions({ height: ref.videoHeight, width: ref.videoWidth });
            }, 100)
        }
    }, [videoRef.current && videoRef.current.videoHeight])

    React.useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return
        }

        const ref = videoRef.current;
        if (ref) {
            if (ref.paused && !time.isPaused) {
                time.handlePause();
                setVideoPlaying(false);
            }
        }


    }, [
        videoRef.current && videoRef.current.paused,
    ])
    React.useEffect(() => {
        if (data.chapters.length) {
            const c = data.chapters.filter(chap => time.timer >= (parseInt(chap.timestamp.minutes, 0) * 60) + parseInt(chap.timestamp.seconds, 0));
            setActiveChapter(c.length ? c[c.length - 1] : data.chapters[0]);
        }
    }, [time.timer])

    const jumpVideoTime = ({ title, timestamp }) => {
        const ref = videoRef.current;
        if (ref) {
            const toTime = (parseInt(timestamp.minutes, 0) * 60) + parseInt(timestamp.seconds, 0)
            time.handleSetTime(toTime);
            ref.currentTime = toTime;
            handleUpdateActivity('chapter-change', { chapter: title });
        }
    }
    const startActivity = async () => {
        const ref = videoRef.current;
        if (ref) {
            const a = await newActivity({ program: data.programId, start: new Date(), event: { day: data.title, type: 'start' } }, dispatch);
            setActiveActivity(a);
            time.handleStart();
            setVideoPlaying(true);
            return ref.play();
        }
    }

    const handleUpdateActivity = (type, data = {}) => {
        if (activeActivity) {
            return updateActivity({
                ...activeActivity,
                event: { day: data.title, timePassed: time.timer, type, ...data },
            }, dispatch);
        }
    }

    const toggleVideoState = () => {
        const ref = videoRef.current;
        if (ref) {
            handleUpdateActivity(videoPlaying ? 'pause' : 'play')
            setVideoPlaying(!videoPlaying)
            time.isPaused ? time.handleStart() : time.handlePause();
            return !ref.paused ? ref.pause() : ref.play()
        }
    }

    const handleActiveChapter = (direction) => {
        const activeChapterIndex = data.chapters.indexOf(activeChapter);
        const toChapter = direction === 'forward' ? data.chapters[activeChapterIndex + 1] : data.chapters[activeChapterIndex - 1]
        jumpVideoTime(toChapter);
    }

    const openFullscreen = () => {
        const video = videoRef.current
        if (video) {
            if (video.webkitEnterFullScreen) {
                video.webkitEnterFullScreen()
            } else if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if (video.webkitRequestFullscreen) {
                video.webkitRequestFullscreen();
            } else if (video.mozRequestFullscreen) {
                video.mozRequestFullscreen();
            }
            handleUpdateActivity('fullscreen');
        }
    }

    const handleCloseModal = () => {
        handleUpdateActivity('close');
        closeModal('workout');
    }
    return (
        <>
            <div ref={headerRef} css={[header, css`padding-top: ${(videoDimensions.height / videoDimensions.width) * 100}%;`]}>
                <div css={[videoComponent]}>
                    <video playsInline ref={videoRef} css={[videoStyle]} autoPlay={videoPlaying}>
                        <source src={data.video_url} type="video/mp4" />
                        <img src={data.img} alt="" />
                    </video>
                    <div css={[videoControls]}>
                        <div css={[css`position: relative;`]}>
                            <div
                                css={[css`position: absolute;right: 0; top: -12px;`]}
                                onClick={openFullscreen}><FullscreenIcon height="22" /></div>
                            <div css={[videoControlsTitleLead]}>Just nu</div>
                            <div css={[videoControlsTitle]}>{activeChapter.title}</div>
                        </div>
                        {videoRef.current && !!videoRef.current.duration && (
                            <div css={[css`display:flex; justify-content: space-between; align-items: center;`]}>
                                <span css={[timelinestampStyle]}>{formatTime(time.timer, { hour: false })}</span>
                                <div css={[css`background-color:#C4C4C4;position: relative;width: 100%; border-radius:10px; height:8px; overflow: hidden;`]}>
                                    <span css={[css`background-color:#000; border-radius: 10px; position: absolute;top: 0; height: 8px; left: 0; width: ${(videoRef.current.currentTime / videoRef.current.duration) * 100}%;`]}></span>
                                </div>
                                <span css={[timelinestampStyle]}>{formatTime(videoRef.current.duration, { hour: false })}</span>
                            </div>
                        )}
                        <div css={[controls]}>
                            <div css={[skipIcon, data.chapters.indexOf(activeChapter) === 0 && disable]}>
                                <BackwardIcon onClick={() => handleActiveChapter('backward')} height="28" />
                                <div css={[skipChapterTitle]}>{data.chapters.indexOf(activeChapter) !== 0 && data.chapters[data.chapters.indexOf(activeChapter) - 1]?.title}</div>
                            </div>
                            <div onClick={!!activeActivity ? toggleVideoState : startActivity}>{videoPlaying ? <PauseIcon height="28" /> : <PlayIcon height="28" />}</div>
                            <div css={[skipIcon, data.chapters.indexOf(activeChapter) === data.chapters.length - 1 && disable]}>
                                <ForwardIcon onClick={() => handleActiveChapter('forward')} height="28" />
                                <div css={[skipChapterTitle]}>{data.chapters.indexOf(activeChapter) !== data.chapters.length - 1 && data.chapters[data.chapters.indexOf(activeChapter) + 1].title}</div>
                            </div>
                        </div>

                    </div>
                </div>
                <div css={[closeButton]} onClick={handleCloseModal} />
            </div>
            <div css={[body, css`margin-top: ${headerRef.current && headerRef.current.clientHeight}px`]}>
                <h3>{data.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: data.day_description }} />
                <div>
                    {data.chapters.map(chapter => (
                        <div key={`${chapter.timestamp.minutes}-${chapter.timestamp.seconds}`} onClick={() => jumpVideoTime(chapter.timestamp)} css={[chapterStyle, activeChapter === chapter && activeStyle]}>
                            <div>{chapter.title}</div>
                            <div>{`${chapter.timestamp.minutes}.${chapter.timestamp.seconds}`}</div>
                        </div>
                    ))}
                </div>
            </div>
            {!activeActivity && (
                <div css={[css` position:fixed; bottom: 20px; width:100%; text-align:center;`]}>
                    <Button onClick={startActivity}>Starta nu!</Button>
                </div>
            )}
        </>
    )
}

const header = css`
@supports (-webkit-touch-callout: none) {
    margin-top: calc(env(safe-area-inset-top) - 6px);
}
padding-top: 56.25%;
position: fixed;
top: 0;
left: 0;right: 0;
overflow: hidden;
box-shadow: 0 0 10px rgba(0,0,0,0.2);
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
background-color: white;
`;

const videoComponent = css`
`;

const videoControls = css`
padding: 20px 20px 10px;
`;

const videoControlsTitleLead = css`
text-align: center;
font-size: 14px;
line-height:1;
`;

const videoControlsTitle = css`
text-align: center;
font-size: 22px;
font-weight: bold;
line-height:1;
margin-bottom: 6px;
`;

const controls = css`
margin: 8px 0;
padding: 0 30px;
display:flex;
justify-content: space-around;
`;

const skipIcon = css`
display:flex;
flex-direction: column;
align-items: center;
`;

const skipChapterTitle = css`
width: 175px;
text-align: center;
`;

const timelinestampStyle = css`
width: 60px;
font-size: 14px;
text-align: center;
`;
const body = css`
padding: 40px 20px 80px;
`;

const videoStyle = css`
width: 100%;
position: absolute;
left: 0;
top: 0;
`;

const closeButton = css`
position: absolute;
top: 12px;
right: 12px;
z-index: 10;
background-color: white;
height: 38px;
width: 38px;
border-radius: 50%;
transform: rotateZ(45deg);
box-shadow: 0 0 10px rgba(0,0,0,0.1);
&::before{
    content: '';
    position: absolute;
    width: 22px;
    height: 3px;
    background-color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
}

&::after{
    content: '';
    position: absolute;
    width: 3px;
    height: 22px;
    background-color: black;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
}
`;

const chapterStyle = css`
display:flex;
justify-content: space-between;
`;

const activeStyle = css`
font-weight: bold;
`;

const disable = css`
fill: #C4C4C4;
visibility: hidden;
pointer-events: none;
`;