/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';

const useTimer = (initialState = 0, active = false, paused = true, direction = 'up') => {
  const [timer, setTimer] = useState(initialState)
  const [isActive, setIsActive] = useState(active)
  const [isPaused, setIsPaused] = useState(paused)
  const countRef = useRef(null)

  useEffect(() => {
    active && handleStart();
    paused && handlePause();
  }, [active, paused])

  useEffect(() => {
    setTimer(initialState);
  }, [initialState])

  useEffect(() => {
    if (direction !== 'up' && timer <= 0) {
      setIsActive(false);
      setIsPaused(true);
      clearInterval(countRef.current)
    }
  }, [timer, direction])

  const handleStart = () => {
    setIsActive(true)
    setIsPaused(false)
    countRef.current = setInterval(() => {
      setTimer((timer) => direction === 'up' ? timer + 1 : timer - 1)
    }, 1000)
  }

  const handlePause = () => {
    clearInterval(countRef.current)
    setIsPaused(true)
  }

  const handleResume = () => {
    setIsPaused(false)
    countRef.current = setInterval(() => {
      setTimer((timer) => direction === 'up' ? timer + 1 : timer - 1)
    }, 1000)
  }

  const handleReset = () => {
    clearInterval(countRef.current)
    setIsActive(false)
    setIsPaused(true)
    setTimer(0)
  }

  const handleSetTime = (time) => {
    setTimer(time)
  }

  return { timer, isActive, isPaused, handleStart, handlePause, handleResume, handleReset, handleSetTime }
}

export default useTimer
