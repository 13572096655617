import React from 'react'
import GradientWrapper from '../components/GradientWrapper'
import Header from '../components/Header'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Button from '../components/Button'
import { useHistory, Redirect } from 'react-router-dom'
import qs from 'query-string';
import { verifyUser } from '../actions/auth.actions'
import { useStore } from '../store/store'


export default function VerifyAccountPage() {
    const router = useHistory();
    // eslint-disable-next-line no-unused-vars
    const [state, dispatch] = useStore();
    const [verified, setVerified] = React.useState(false);
    const [error, setError] = React.useState('');
    const { id, t } = qs.parse(router.location.search);

    React.useEffect(() => {
        verifyUser({ id, token: t }, dispatch).then(res => {
            if (res.status === 200) {
                setVerified(true);
            }
            if (res.status === 417) {
                setError('Det verkar som att du redan aktiverat ditt konto. Har du glömt lösenordet? Annars logga in :)')
            }
        })
    }, [id, dispatch, t])

    if (state.auth.isAuthenticated) {
        return (
            <Redirect to="/" />
        )
    }

    return (
        <GradientWrapper>
            <div css={[css`display:flex; flex-direction: column; justify-content:space-between;height: 100%;`]}>
                <Header title={verified ? 'Konto verifierat!' : error ? 'Hoppsan :)' : 'Verifierar konto...'} />
                <div css={[body]}>
                    {!verified && !error ? (
                        <>
                            <h3>Jobbar påt...</h3>
                            <p>Snart kommer du kunna logga in! :)</p>
                        </>
                    ) : verified && !error ? (
                        <div>
                            <h3>Nu jäklar kör vi!</h3>
                            <Button align="center" onClick={() => router.push('/login')}>Logga in!</Button>
                        </div>
                    ) : (<div>
                        <h3>Oops...</h3>
                        <p>{error}</p>
                        <div css={[css`margin-bottom: 8px`]}>
                            <Button align="center" onClick={() => router.push('/forgot-password')}>Jag har glömt lösenordet</Button>
                        </div>
                        <Button align="center" onClick={() => router.push('/login')}>Logga in!</Button>

                    </div>)}
                </div>
            </div>
        </GradientWrapper>
    )
}
const body = css`
box-sizing: border-box;
height: calc(100% - 100px);
display:flex;
flex-direction:column;
justify-content:flex-end;
padding: 0 20px calc(env(safe-area-inset-bottom) + 12px);

h2{
    margin-bottom: 0;
}
p {
    margin-bottom: 24px;
}
`;