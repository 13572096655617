import { AUTH_USER_SUCCESS, CART_ADD_ITEM, CART_NOTI_DISMISS, CART_REMOVE_ITEM, FETCH_USER_PROGRAMS_SUCCESS, LOGOUT_USER, USER_FETCH_SUCCESS } from '../constants/actions.constants';
import { deleteStorage } from '../services/localStorage';
import initialState from './initialState';

export function reducer(state, action) {
    switch (action.type) {
        case AUTH_USER_SUCCESS:
            return { ...state, auth: { isAuthenticated: true, user: { ...state.auth.user, ...action.payload.user } } };
        case LOGOUT_USER:
            deleteStorage('token');
            window.location.reload();
            return initialState;
        case USER_FETCH_SUCCESS:
            return { ...state, auth: { ...state.auth, user: { ...state.auth.user, ...action.payload } } };
        case FETCH_USER_PROGRAMS_SUCCESS:
            return { ...state, auth: { ...state.auth, user: { ...state.auth.user, programs: action.payload } } }
        case CART_ADD_ITEM:
            const product = state.products.find(p => p._id === action.payload);
            return { ...state, cart: { items: [...state.cart.items, product], noti: `${product.name} lades i kundvagnen` } }
        case CART_REMOVE_ITEM:
            return { ...state, cart: { items: state.cart.items.filter(item => item.id !== action.payload.id), noti: `${action.payload.name} togs bort` } }
        case CART_NOTI_DISMISS:
            return { ...state, cart: { ...state.cart, noti: '' } }

        case 'get_products':
            return { ...state, products: action.payload }
        case 'open_modal':
            return { ...state, modals: [...state.modals, action.payload] }
        case 'close_modal':
            return { ...state, modals: state.modals.filter(modal => modal.component !== action.payload) }
        default:
            return state;
    }
}