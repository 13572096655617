import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Header from '../components/Header';
import ProgramList from '../components/ProgramList/ProgramList';
import ScreenWrapper from '../components/ScreenWrapper'
import { useStore } from '../store/store';
import { ReactComponent as MoreIcon } from '../assets/icons/ellipsis-v.svg';
import Contextmenu from '../components/Contextmenu';
import { useHistory } from 'react-router-dom';
import { logoutUser } from '../actions/auth.actions';
import Button from '../components/Button';
import { getMe } from '../actions/user.actions';

export default function UserPage() {
    const [state, dispatch] = useStore();
    const router = useHistory();
    const {auth: {user: {programs}}} = state;
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getMe(dispatch).then((res)=>{
            setLoading(false);
        })
    }, [dispatch]);

    return (
        <ScreenWrapper customHeader={<HeaderComponent />}>
            {programs && programs.length ? <ProgramList loading={loading} programs={state.auth.user.programs} /> : (
                <div css={[css`display:flex; justify-content:center; flex-direction:column;height: 100%; text-align: center;`]}>
                    Här kommer du att se dina köpta program.
                    <div css={[css`margin-top: 20px`]}>
                        <Button align="center" onClick={() => router.push('/store')}>Gå till butiken</Button>
                    </div>
                </div>
            )}
        </ScreenWrapper>
    )
}

const HeaderComponent = () => {
    const router = useHistory();
    const [contextOpen, setContextOpen] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [state, dispatch] = useStore();

    const toggleContextOpen = () => {
        setContextOpen(!contextOpen)
    }
    const handleItemClick = (action) => {
        if (action === 'signout') {
            logoutUser(dispatch);
            router.push('/login');

        }
        if (action === 'settings') {
            router.push('/user/settings');
        }
    }

    const contextItems = [
        {
            label: 'Inställningar',
            name: 'settings',
        },
        {
            label: 'Logga ut',
            name: 'signout'
        }
    ]

    return (
        <div>
            <Header title="Mina|Program">
                <div css={[css`position: relative;margin-top: 30px;`]}>
                    <MoreIcon width="24" height="28" onClick={toggleContextOpen} />
                    <Contextmenu open={contextOpen} toggle={toggleContextOpen} items={contextItems} handleItemClick={handleItemClick} />
                </div>
            </Header>

        </div >
    )
}

