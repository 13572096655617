import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_NOTI_DISMISS,
    baseApiUrl
} from '../constants/actions.constants';
import axios from '../services/axios';

export const addToCart = (item, dispatch) => {
    dispatch({
        type: CART_ADD_ITEM,
        payload: item
    })

}

export const removeFromCart = (item, dispatch) => {
    dispatch({
        type: CART_REMOVE_ITEM,
        payload: item
    })

}

export const dismissCartNoti = (dispatch) => {
    dispatch({
        type: CART_NOTI_DISMISS,
    })
}
export const createKlarnaOrder = () => {
    return axios({
        url: baseApiUrl + '/order',
        method: 'POST'
    }).then(res=> {
        return res;
    })
}