import React from 'react';
//import axios from 'axios';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { StoreProvider } from './store/store';
import initialState from './store/initialState';
import { reducer } from './store/reducer';
import Modal from './components/Modal';
import NavigationBar from './components/NavigationBar';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import UserPage from './pages/user.page';
import ShopPage from './pages/shop.page';
import PrivateRoute from './components/PrivateRoute';

import { ReactComponent as Store } from './assets/icons/store.svg';
import { ReactComponent as Crown } from './assets/icons/crown.svg';
import { ReactComponent as User } from './assets/icons/user.svg';
import { ReactComponent as IOSShare } from './assets/icons/ios_share.svg';
import { ReactComponent as IOSAdd } from './assets/icons/ios_add.svg';
import LoginPage from './pages/login.page';
import { parseJwt } from './services/jwt-helper';
import { getStorage } from './services/localStorage';
import ResetPassRequest from './pages/reset-password-request.page';
import ResetPass from './pages/reset-password.page';
import SignupPage from './pages/signup.page';
import VerifyAccountPage from './pages/verify.page';
import UserSettingsPage from './pages/user.settings.page';
import Cart from './components/Cart';
import { checkAppVersion } from './actions/auth.actions';
import AdminPage from './pages/admin.page';

const style = css`
height: 100%;
background-color: #fff;
`;

const newVersionNoti = css`
position: relative; 
padding: 8px 30px; 
margin-top: calc(env(safe-area-inset-top) - 6px); 
box-sizing: border-box; 
top: 0; 
left: 0; 
width: 100%; 
background-color: #FEDDFE; 
text-align: center;
font-weight: bold;
`;

const installMessageNoti = css`
position: fixed;
bottom: 20px;
background-color:white;
z-index:99999;
padding: 0px 10px 0 4px;
margin: 0 10px;
box-shadow: 0 0 10px rgba(0,0,0,0.2);
border-radius:10px;
text-align:center;
display: flex;
align-items: center;

svg {
  vertical-align: middle;
}
&:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-top-color: white;
	border-bottom: 0;
	margin-left: -20px;
	margin-bottom: -20px;
}
`;

const routes = [
  { path: '/user', icon: { component: User, props: { width: 24, height: 28 } } },
  { path: '/store', icon: { component: Store, props: { width: 34, height: 28 } } },
];


function App() {
  const [versionMismatch, setVersionMismatch] = React.useState(false);
  const [latestVersion, setLatestVersion] = React.useState(false);
  const [installMessage, setInstallMessage] = React.useState(false);
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
  const noShowVersionMismatch = ['/reset-password', '/login', '/verify', '/forgot-password', '/signup']
  const disableVersionMismatchNoti = noShowVersionMismatch.indexOf(window.location.pathname) > -1;

  React.useEffect(() => {
    const checkVersion = async () => {
      const serverVersion = await checkAppVersion();
      setLatestVersion(serverVersion);
      return serverVersion;
    }
    const compareVersions = async () => {
      const latest = await checkVersion();
      if (localStorage.getItem('app-version') !== latest) {
        console.log(`App version mismatch, latest version is ${latest}, your version is ${localStorage.getItem('app-version')}`)
        setVersionMismatch(true);
      }
    }
    compareVersions();

    if (isIos() && !isInStandaloneMode()) {
      setInstallMessage(true);
    }
  }, [])

  React.useEffect(()=>{
    if(getInitialState().auth.user.role === 'admin'){
      routes.push(  { path: '/admin', icon: { component: Crown, props: { width: 34, height: 28 } } },
      )
    }
  },[]);

  function reloadPage() {
    localStorage.setItem('app-version', latestVersion)
    window.location.href = `${window.location.href}?v=${latestVersion}`
  }
  function handleDismissInstallMessage(){
    setInstallMessage(false);
  }

  function getInitialState() {
    const theInitialState = { ...initialState };
    try {
      const token = getStorage('token');
      if (token) {
        const user = parseJwt(token);
        theInitialState.auth = { isAuthenticated: true, user };
      }
    } catch (e) {
      console.log(e);
      return initialState;
    }
    return theInitialState;
  }
  return (
    <StoreProvider initialState={getInitialState()} reducer={reducer} >
      <Router>
        <div css={style}>
          <div css={[css`position:fixed; top: 0; height: calc(env(safe-area-inset-top) - 6px); width: 100%; background-color: #A691E3; z-index:999;`]}></div>
          {versionMismatch && !disableVersionMismatchNoti && (
            <div css={[newVersionNoti]} onClick={reloadPage}>En ny version finns tillgänglig. <br />Klicka här för att uppdatera</div>
          )}
          {installMessage && (
            <div css={[installMessageNoti]} onClick={handleDismissInstallMessage}> <IOSShare height="65" width="65"/><div>Lägg till appen på hemskrämen <IOSAdd height="18" width="18" /> för en mycket bättre upplevelse!</div></div>
          )}
          <Cart />
          <Switch>
            <PrivateRoute path="/program/:id" name="program" />
            <PrivateRoute path="/user/settings" name="user" component={UserSettingsPage} />
            <PrivateRoute path="/admin" name="admin" component={AdminPage} />
            <PrivateRoute path="/user" name="user" component={UserPage} />
            <PrivateRoute path="/store" name="store" component={ShopPage} />
            <Route path="/forgot-password" name="forgot-password" component={ResetPassRequest} />
            <Route path="/reset-password" name="reset-password" component={ResetPass} />
            <Route path="/login" name="login" component={LoginPage} />
            <Route path="/signup" name="signup" component={SignupPage} />
            <Route path="/verify" name="verify" component={VerifyAccountPage} />
            <PrivateRoute exact path="/" redirect="/user" component={UserPage} />
          </Switch>
          <NavigationBar routes={routes} />
        </div>
      </Router>
      <Modal />
    </StoreProvider>
  );
}

export default App;
