import axiosLibrary from 'axios';
import { getStorage } from './localStorage';
async function getToken() {
  return getStorage('token');
}
const axios = axiosLibrary.create();
axios.interceptors.request.use(async (config) => {
const token = await getToken();
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  return config;

}, error => {
  console.log(error)
  if (!process.browser) {
    console.error(error.config.url, error.message || 'Error in request');
  }
  return Promise.reject(error);
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (!process.browser) {
      if (error.response) {
//        console.error("error from"error.config.url, error.response.data);
//        console.error(error.config.url, error.response.status);
//        console.error(error.config.url, error.response.headers);
      } else {
        //       console.error(error.config.url, error.message);
      }
    }

    return Promise.reject(error);
  },
);
export default axios;
