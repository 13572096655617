import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export default function InputField({ label, name, type = 'text', onChange, error, ...props}) {

    const handleOnChange = (e) => {
        onChange(e.target.value);
    }

    const fieldError = error && error.length ? error.find(err=>err.field === name) : false;

    return (
        <div css={inputWrapper}>
            <label htmlFor={name} css={[inputLabelStyle]}>{label}</label>
            <input type={type} name={name} id={name} css={[inputStyle]}  onChange={handleOnChange} {...props}/>
            {fieldError && (
                <div css={errorView}>
                    <div css={errorText}>{fieldError.message}</div>
                </div>
            )}
        </div>
    )
}

const inputWrapper = css`
margin-bottom: 8px;
`;

const inputStyle = css`
    width: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
    font-size: 16px;
    border-radius: 20px;
    border: 0;
    outline: 0;
`;
const inputLabelStyle = css`
    display: block;
    font-weight: 500;
    margin: 0 0 5px 20px;
    font-size: 18px;
`;

const errorView = css`
    margin-top: 4px;
`;

const errorText = css`
    text-align:center;
    color: red;
    font-size: .9rem;
`;
