import React from 'react'
import axios from '../services/axios';
import { useStore } from '../store/store';
import ProgramList from '../components/ProgramList/ProgramList';
import ScreenWrapper from '../components/ScreenWrapper';
import { baseApiUrl } from '../constants/actions.constants';
import { getMe } from '../actions/user.actions';

export default function ShopPage() {
    const [state, dispatch] = useStore();
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        getMe(dispatch)
    }, [dispatch]);

    React.useEffect(() => {
        axios({
            url: baseApiUrl + '/products',
            method: 'GET'

        }).then(res => {
            dispatch({
                type: 'get_products',
                payload: res.data
            })
            setLoading(false);
        })
    }, [dispatch]);

    return (
        <ScreenWrapper title="Köp|Program">
            {state.products && <ProgramList loading={loading} programs={state.products} />}
        </ScreenWrapper>
    )
}
